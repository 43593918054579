import { type Table, flexRender } from "@tanstack/react-table";
import type { Dispatch, SetStateAction } from "react";
import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useSortParams } from "~/providers/SortParamsProvider";
const Header = <T,>({
  table,
  setShowNewFieldModal,
  hideFinalColumn
}: {
  table: Table<T>;
  setShowNewFieldModal: Dispatch<SetStateAction<boolean>>;
  hideFinalColumn?: boolean;
}) => {
  const headerIdToFieldMap: Record<string, string> = {
    dependencyStatusNumber: "dependencyStatusNumber",
    activity: "activity",
    depActivity: "depActivity",
    reputation: "reputation",
    latestCommitDate: "latestCommitDate",
    commitCount: "commitCount",
    totalStars: "totalStars",
    githubName: "githubName",
    followers: "followers",
    location: "location",
    topOrg: "topOrg"
  };
  const {
    userSort,
    setUserSort
  } = useSortParams();
  const handleSort = (field: string) => {
    const isAlreadyThisField = userSort?.field === field;
    const isAlreadyDesc = userSort?.direction === "desc";
    const initialDirection = isAlreadyDesc ? "asc" : "desc";
    const direction = !isAlreadyThisField ? userSort?.direction : initialDirection;
    setUserSort({
      field,
      direction
    });
  };
  return <thead className="sticky top-0 z-30" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      {table.getHeaderGroups().map(headerGroup => <tr className="" key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
        const firstClass = index === 0 ? "sticky left-0 z-30 bg-gray-50 border-b rounded-tl-lg text-left" : "border-x z-30 bg-gray-50";
        const roundedClass = firstClass + " ";
        const field = headerIdToFieldMap[header.column.id];
        return <th className={` p-0 text-left font-normal ${roundedClass}`} key={header.id}>
                <div className="w-full px-2 py-3">
                  {header.isPlaceholder ? null : header.column.getCanSort() ? <button className={"flex select-none items-center justify-start gap-2"} onClick={() => handleSort(field)}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {field && <>
                          {field !== userSort?.field && <ChevronUpDownIcon className="w-4 h-4" />}
                          {field === userSort?.field && userSort.direction === "desc" && <ChevronDownIcon className="w-4 h-4" />}
                          {field === userSort?.field && userSort.direction === "asc" && <ChevronUpIcon className="w-4 h-4" />}
                        </>}
                    </button> : <div>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>}
                </div>
              </th>;
      })}
          {!hideFinalColumn && <th className=" z-40 border-b border-l border-gray-100 bg-gray-50">
              <button className="flex w-12 items-center justify-center" onClick={() => {
          setShowNewFieldModal(true);
        }}>
                <PlusIcon className="h-6 w-6 text-gray-400" />
              </button>
            </th>}
        </tr>)}
    </thead>;
};
export default Header;