import type { Dispatch, SetStateAction } from "react";
import AddCustomFieldModal from "../AddCustomFieldModal";
const TableLayout = ({
  children,
  toggleHook,
  type
}: {
  children: React.ReactNode[];
  type: string;
  toggleHook: [boolean, Dispatch<SetStateAction<boolean>>];
}) => {
  const [showNewFieldModal] = toggleHook;
  return <div className="-mr-8 overflow-hidden text-muted" data-sentry-component="TableLayout" data-sentry-source-file="TableLayout.tsx">
      {showNewFieldModal && <AddCustomFieldModal contactType={type} toggleHook={toggleHook} />}
      <div id="table-container" className="h-[calc(100vh_-_220px)] w-full overflow-scroll -mr-8">
        <table className="overflow-x-hidden">{children}</table>
      </div>
    </div>;
};
export default TableLayout;