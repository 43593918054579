import type { VirtualItem } from "@tanstack/react-virtual";
import { useEffect } from "react";
const useFetchWhenDone = (handleGetNextPage: () => void, virtualRows: VirtualItem[], rowCount: number, isFetchingNextPage?: boolean, hasNextPage?: boolean) => {
  useEffect(() => {
    const [lastItem] = [...virtualRows].reverse();
    if (!lastItem) {
      return;
    }
    if (lastItem.index >= rowCount - 1 && hasNextPage && !isFetchingNextPage) {
      handleGetNextPage();
    }
  }, [hasNextPage, handleGetNextPage, virtualRows, isFetchingNextPage, rowCount]);
};
export default useFetchWhenDone;