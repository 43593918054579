import { Dialog } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState, type Dispatch, type SetStateAction } from "react";
import Button from "~/components/base/Button";
import DropDown from "~/components/base/DropDown";
import Input from "~/components/base/Input";
import { useList, useListRefetch } from "~/providers/ListProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { api } from "~/utils/api";
const AddCustomFieldModal = ({
  toggleHook,
  contactType
}: {
  toggleHook: [boolean, Dispatch<SetStateAction<boolean>>];
  contactType: string;
}) => {
  const [showDialog, setShowDialog] = toggleHook;
  const listRefetch = useListRefetch();
  const onSuccess = () => {
    listRefetch().catch(console.error);
    setShowDialog(false);
  };
  const {
    mutate
  } = api.list.listAddCustomField.useMutation({
    onSuccess
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [type, setType] = useState("text");
  const [warning, setWarning] = useState("");
  const [value, setValue] = useState("");
  const {
    activeTeamAccount
  } = useTeamAccount();
  const list = useList();
  const items = [{
    name: "Text",
    value: "text"
  }, {
    name: "Number",
    value: "number"
  }, {
    name: "Date",
    value: "date"
  }, {
    name: "Boolean",
    value: "boolean"
  }];
  const handleSave = () => {
    if (!value) {
      setWarning("Please enter a field name");
      return;
    }
    setWarning("");
    mutate({
      contactType,
      type,
      key: value,
      teamAccountId: activeTeamAccount?.id,
      listId: list?.id ?? ""
    });
  };
  return <Dialog open={showDialog} onClose={() => setShowDialog(false)} data-sentry-element="Dialog" data-sentry-component="AddCustomFieldModal" data-sentry-source-file="AddCustomFieldModal.tsx">
      <div className="fixed inset-0 z-50 overflow-y-auto bg-black/40">
        <div className="flex min-h-full items-center justify-center px-8 py-4 text-center">
          <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white px-16 py-4 text-left align-middle shadow-xl transition-all" data-sentry-element="unknown" data-sentry-source-file="AddCustomFieldModal.tsx">
            <Dialog.Title as="h3" className=" flex flex-col content-center items-center justify-center gap-2 text-lg leading-6 " data-sentry-element="unknown" data-sentry-source-file="AddCustomFieldModal.tsx">
              <span className="py-2 text-2xl">Add Custom Field</span>
              <Input value={value} setValue={setValue} placeholder="Field Name" data-sentry-element="Input" data-sentry-source-file="AddCustomFieldModal.tsx" />
              <div className="relative flex w-full items-start gap-2">
                <span>Type:</span>
                <button onClick={() => {
                setShowDropdown(true);
              }} className="flex items-center gap-2">
                  <span>{type}</span>
                  <ChevronDownIcon className="h-4 w-4" data-sentry-element="ChevronDownIcon" data-sentry-source-file="AddCustomFieldModal.tsx" />
                </button>
                <DropDown className="top-6 bg-offwhite" filterOpen={showDropdown} closeDropDown={() => {
                setShowDropdown(false);
              }} selectItem={value => {
                setType(value);
              }} items={items} data-sentry-element="DropDown" data-sentry-source-file="AddCustomFieldModal.tsx" />
              </div>
              {warning && <span className="text-red-500">{warning}</span>}
              <Button onClick={handleSave} className="w-full" data-sentry-element="Button" data-sentry-source-file="AddCustomFieldModal.tsx">
                Save
              </Button>
            </Dialog.Title>

            <div className="flex flex-col gap-4 py-4 "></div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>;
};
export default AddCustomFieldModal;